
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl } from '../../../utils/network-utils';
import { element } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class OfferedtripsService {

  constructor(private http: HttpClient) {
  }


  getDashboardCount(): Observable<any> {

    return this.http.get<any>(urls.BASE_URL + urls.FACILITY_TRIPS_COUNTER_URL)
      .pipe(
        catchError(errorHandl)
      );
  }

  getTripsList(payload): Observable<any> {
    let params = new HttpParams();
    params = params.append('status', payload.status);
    return this.http.get<any>(urls.BASE_URL + urls.FACILITY_TRIPS_URL, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getCTCTripsList(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_CTC_TRIPS)
      .pipe(
        catchError(errorHandl)
      );
  }
  getAgentTripsList(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_AGENT_TRIPS)
      .pipe(
        catchError(errorHandl)
      );
  }

  getCTCClaimedTrips(startDate, endDate): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_CTC_CLAIMED_TRIPS + '?endDate=' + endDate + '&startDate=' + startDate)
      .pipe(
        catchError(errorHandl)
      );
  }

  putCTCTripClaim(payload): Observable<any> {
    return this.http.put<any>(urls.BASE_URL + urls.GET_CTC_TRIPS, payload)
      .pipe(
        catchError(errorHandl)
      );
  }


  changeProviderStatus(payload): Observable<any> {

    return this.http.put<any>(urls.BASE_URL + urls.FACILITY_TRIPS_URL + `/${payload._id}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  getTripsLogs(): Observable<any> {

    return this.http.get<any>(urls.BASE_URL + urls.FACILITY_TRIPS_URL + `/logs`)
      .pipe(
        catchError(errorHandl)
      );
  }

}
