import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { PDFDocument } from 'pdf-lib';
import { urlToArrayBuffer, getImageAsEmbedNew, removeNonAllowedCharactersFromPDF } from 'src/app/utils/utils.common';

@Injectable({
  providedIn: 'root'
})
export class ModivcareReportService {

  constructor() { }

  async fillPDF(pdfBuffer: ArrayBuffer, trips, user, driver): Promise<ArrayBuffer> {
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const form = pdfDoc.getForm();

    const company = user.company ? user.company : user;
    const lastIndex = (trips.length) -1
    const lastTrip = trips[lastIndex]
    const weekendDate = moment(lastTrip.scheduleTime).tz(company.timeZone).endOf('week').format("MM/DD/YYYY")

    if (company) {
      form.getTextField("provider").setText(company.displayName);

    if(company.npi)
      form.getTextField("provider_id").setText(company.npi);

      if(weekendDate){
        form.getTextField("date").setText(weekendDate+'');
      }
      if(company.state){
        form.getTextField("state").setText(company.state);
      }


      if (driver) {
        form.getTextField("driver_name").setText(driver.displayName);
      }

      for (const [index, trip] of trips.entries()) {
        if (trip) {
          if (trip.driver) {
            form.getTextField("vin" + index).setText((trip.driver.vin).slice(-6));
          }
          form.getTextField("trip_date" + index).setText(moment(trip.scheduleTime).tz(company.timeZone).format('MM/DD/YYYY'));
          form.getTextField("trip_id" + index).setText(trip.tripId);
          if (trip.priorityClient && trip.priorityClient.displayName) {
            form.getTextField("member_name" + index).setText(trip.priorityClient.displayName);
          }
          if (trip.scheduleTime) {
            form.getTextField("pickup_time" + index).setText(moment(trip.scheduleTime).tz(company.timeZone).format('HH:mm'));
          }
          if (trip.appointmentTime) {
            form.getTextField("dropoff_time" + index).setText(moment(trip.appointmentTime).tz(company.timeZone).format('HH:mm'));
          }
          // form.getTextField("willcall_time" + index).setText('');
          form.getTextField("total_mileage" + index).setText(trip.milage + '');
          // form.getTextField("late_reason" + index).setText('');
          if (trip.job) {
            if (trip.job.jobFareDetail && trip.job.jobFareDetail.jobFee) {
              form.getTextField("amount" + index).setText(trip.job.jobFareDetail.jobFee + '');
            }
            if (trip.job.odometerBegin) {
              form.getTextField("start_odometer" + index).setText(trip.job.odometerBegin);
            }
            if (trip.job.odometerStop) {
              form.getTextField("end_odometer" + index).setText(trip.job.odometerStop);
            }
            debugger;
            if (trip.job.signatureImage) {
              const jpgImageBytes: any = await urlToArrayBuffer(trip.job.signatureImage);
              const pngImage = await getImageAsEmbedNew(pdfDoc, trip.job.signatureImage, jpgImageBytes);
              if (pngImage)
                form.getTextField("signature" + index).setImage(pngImage);
            }

          }
        }
      }


      debugger;
      const jpgImageBytes: any = await urlToArrayBuffer(driver.driverSignatureImage);
      const pngImage = await getImageAsEmbedNew(pdfDoc, driver.driverSignatureImage, jpgImageBytes);
      debugger;
      if (pngImage)
        form.getTextField('driver_signature').setImage(pngImage);
      form.getTextField("driver_name").setText(driver.displayName);
      form.getTextField("driver_license_number").setText(driver.driverLicenseNumber);


    }

    // remove non ASCII
    await removeNonAllowedCharactersFromPDF(form);
    const modifiedBuffer = await pdfDoc.save(); // Save the modified PDF back to a buffer
    return modifiedBuffer;
  }

  async getModivcareReport(payload, company, driver) {
    const pdfArray = [];
    let newtemp = []
    for (const [index, item] of payload.entries()) {
      newtemp.push(item)
      if (index === 7) {
        const response = await fetch("assets/documents/modivcare-report.pdf");
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillPDF(pdfBuffer, newtemp, company, driver);
        pdfArray.push(pdfBuffer);
        newtemp = [];
      } else if (index > 7 && (index - 7) % 8 === 0) {
        const response = await fetch("assets/documents/modivcare-report-blank.pdf");
        let pdfBuffer = await response.arrayBuffer();
        pdfBuffer = await this.fillPDF(pdfBuffer, newtemp, company, driver);
        pdfArray.push(pdfBuffer);
        newtemp = [];
      }
    }

    if (!pdfArray.length && newtemp.length) {
      const response = await fetch("assets/documents/modivcare-report.pdf");
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillPDF(pdfBuffer, newtemp, company, driver);
      pdfArray.push(pdfBuffer);
      newtemp = [];
    } else if (pdfArray.length && newtemp.length) {
      const response = await fetch("assets/documents/modivcare-report-blank.pdf");
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillPDF(pdfBuffer, newtemp, company, driver);
      pdfArray.push(pdfBuffer);

    }

    const pdfDoc = await PDFDocument.create();
    for (const pdfBytesData of pdfArray) {
      const sourcePDF = await PDFDocument.load(pdfBytesData);
      const [page] = await pdfDoc.copyPages(sourcePDF, [0]);
      pdfDoc.addPage(page);
    }
    const mergedPdfBytes = await pdfDoc.save();
    if (mergedPdfBytes) {
      const currentDate = moment().format("MM-DD-YYYY");
      const fileName = `modivcare_trip_logs_${currentDate}.pdf`;
      const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }


 async getResubmissionReport(payload, company, driver , vin,date){
  const pdfArray = [];
  let newtemp = []
  for (const [index, item] of payload.entries()) {
    newtemp.push(item)
    if (index === 9) {
      const response = await fetch("assets/documents/resubmission-form.pdf");
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillResubmissionReport(pdfBuffer, newtemp, company, driver, vin);
      pdfArray.push(pdfBuffer);
      newtemp = [];
    } else if (index > 9 && (index - 9) % 10 === 0) {
      const response = await fetch("assets/documents/resubmission-form-blank.pdf");
      let pdfBuffer = await response.arrayBuffer();
      pdfBuffer = await this.fillResubmissionReport(pdfBuffer, newtemp, company, driver ,vin);
      pdfArray.push(pdfBuffer);
      newtemp = [];
    }
  }

  if (!pdfArray.length && newtemp.length) {
    const response = await fetch("assets/documents/resubmission-form.pdf");
    let pdfBuffer = await response.arrayBuffer();
    pdfBuffer = await this.fillResubmissionReport(pdfBuffer, newtemp, company, driver, vin);
    pdfArray.push(pdfBuffer);
    newtemp = [];
  } else if (pdfArray.length && newtemp.length) {
    const response = await fetch("assets/documents/resubmission-form-blank.pdf");
    let pdfBuffer = await response.arrayBuffer();
    pdfBuffer = await this.fillResubmissionReport(pdfBuffer, newtemp, company, driver, vin);
    pdfArray.push(pdfBuffer);

  }

  const pdfDoc = await PDFDocument.create();
  for (const pdfBytesData of pdfArray) {
    const sourcePDF = await PDFDocument.load(pdfBytesData);
    const [page] = await pdfDoc.copyPages(sourcePDF, [0]);
    pdfDoc.addPage(page);
  }
  const mergedPdfBytes = await pdfDoc.save();
  if (mergedPdfBytes) {
    const currentDate = moment(date).format("MM-DD-YYYY");
    const fileName = `resubmissionform_report_${currentDate}.pdf`;
    const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }
 }

 async fillResubmissionReport(pdfBuffer: ArrayBuffer, trips, user, driver, vin): Promise<ArrayBuffer> {
  const pdfDoc = await PDFDocument.load(pdfBuffer);
  const form = pdfDoc.getForm();

  const company = user.company ? user.company : user;
  const lastIndex = (trips.length) -1
  const lastTrip = trips[lastIndex]
  const weekendDate = moment(lastTrip.scheduleTime).tz(company.timeZone).endOf('week').format("MM/DD/YYYY")

  if (company) {
    form.getTextField("company_name").setText(company.displayName);
    if (driver) {
      form.getTextField("driver_name").setText(driver.displayName);
    }
    if(vin){
      form.getTextField("vin").setText(vin);
    }
    for (const [index, trip] of trips.entries()) {
      if (trip) {
        form.getTextField("date_" + index).setText(moment(trip.scheduleTime).tz(company.timeZone).format('MM/DD/YYYY'));
        form.getTextField("trip_id_" + index).setText(trip.tripId);
        if (trip.priorityClient && trip.priorityClient.displayName) {
          form.getTextField("member_name" + index).setText(trip.priorityClient.displayName);
        }
        if (trip.tripRequirement) {
          if(trip.tripRequirement === "AMB" || trip.tripRequirement === "Standard"){
            form.getTextField("Los" + index).setText("A");
          }else if(trip.tripRequirement === "WAV"){
            form.getTextField("Los" + index).setText("W");
          }else {
            form.getTextField("Los" + index).setText("S");
          }
        
        }
        if (trip.job && trip.job.trackInfoJob && trip.job.trackInfoJob.jobArrived) {
          form.getTextField("pu_time" + index).setText(moment(trip.job.trackInfoJob.jobArrived.time).tz(company.timeZone).format('HH:mm'));
        }
        if (trip.job && trip.job.trackInfoJob && trip.job.trackInfoJob.jobFinished) {
          form.getTextField("do_time" + index).setText(moment(trip.job.trackInfoJob.jobFinished.time).tz(company.timeZone).format('HH:mm'));
        }
        // form.getTextField("willcall_time" + index).setText('');
        form.getTextField("total_miles" + index).setText(trip.milage + '');
        form.getTextField("reason" + index).setText("ERROR: The trip submitted is already denied.");
      }
    }
  }

  // remove non ASCII
  await removeNonAllowedCharactersFromPDF(form);
  const modifiedBuffer = await pdfDoc.save(); // Save the modified PDF back to a buffer
  return modifiedBuffer;
}
}
