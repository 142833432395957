import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, ViewChild, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import { takeUntil } from 'rxjs/operators';
import { Events } from 'src/app/utils/event-utils';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ActivatedRoute } from '@angular/router';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { animate, style, transition, trigger } from "@angular/animations";
import { getBrokersList, getTimeAndDistance } from 'src/app/utils/utils.common';
import { AgmMap } from '@agm/core';
import * as moment from 'moment-timezone';
import { environment } from 'src/environments/environment';
import { urls } from 'src/app/utils/url-utils';
declare var $;
@Component({
  selector: 'app-trip-assign-modal',
  templateUrl: './trip-assign-modal.component.html',
  styleUrls: ['./trip-assign-modal.component.css'],
  animations: [
    trigger("openCloseAssignSlideOut", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateX(100%)" }),
        animate(
          "750ms ease-in-out",
          style({ opacity: 1, transform: "translateX(0)" })
        )
      ]),
      transition(":leave", [
        style({ opacity: 1, transform: "translateX(0)" }),
        animate(
          "750ms ease-in-out",
          style({ opacity: 0, transform: "translateX(-100%%)" })
        )
      ])
    ])
  ]
})
export class TripAssignModalComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild("AgmMap", { static: true }) agmElement: AgmMap;
  @Input('assignModalObj') trip: any;
  @Input() componentType: string;
  @Input() secondaryCompType: string;
  @Output() tripAssigned = new EventEmitter<any>();
  @Output() onCloseModal = new EventEmitter<any>();
  agmMap;
  user;
  searchDriver;
  selectedDriver = null;
  googleSelectedDriver = null;
  nearbyDrivers = [];
  activeDrivers = [];
  manifestList = [];
  isSubmitted = false;
  isAttendee = "";
  isLivePrediction = false;

  runId;
  selectedUberVehicle;
  requestType = null;
  multiAssignTripNote = null;
  cancelTripModalObj;
  origin = { lat: 0.00000, lng: 0.00000 };
  driverLoc = { lat: 0.00000, lng: 0.00000 };
  destination = { lat: 0.00000, lng: 0.00000 };
  uberVehicles;
  isUberDataLoading = false;
  timeZone;
  isUberIntegeration = false;

  constructor(
    private driverService: DriversService,
    private assignTripService: AssigTripsService,
    private socketService: SocketService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {

    this.getUser();
    // if (this.componentType !== 'Multiple Assign') {
    //   this.getNearbyDriver(this.trip.jobOriginLatitude, this.trip.jobOriginLongitude);
    // } else {
    // this.getDriverList();
    // }
    if (this.trip) {
      this.origin = {
        lat: this.trip.jobOriginLatitude,
        lng: this.trip.jobOriginLongitude
      };
      this.destination = {
        lat: this.trip.jobDestinationLatitude,
        lng: this.trip.jobDestinationLongitude
      };
    }
    this.getNearbyDriver(this.trip.jobOriginLatitude, this.trip.jobOriginLongitude);
    this.listenSocket();

    // this.isAttendee = trip.attendee
  }


  ngOnChanges(changes): void {
    if (changes.trip && changes.trip.currentValue) {
      if (changes.trip.currentValue.attendee)
        this.isAttendee = changes.trip.currentValue.attendee;
      if (changes.trip.currentValue.driver)
        this.selectedDriver = changes.trip.currentValue.driver;
    }
  }

  ngAfterViewInit() {
    // this.agmElement.mapReady
    //   .pipe(takeUntil(componentDestroyed(this)))
    //   .subscribe((map) => {
    //     this.agmMap = map;
    //    let trafficLayer = new google.maps.TrafficLayer();
    //     trafficLayer.setMap(this.agmMap);
    //   });
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = user.timeZone;
          if(user.hasOwnProperty('isUber')) {
            this.isUberIntegeration = user.isUber
          }
        }
      });
  }

  getNearbyDriver(lat, lng) {
    let data = {
      isNearBy: true,
      companyType: this.trip.companyType,
      // isReassigned: this.trip.driver ? true : false,
      isReassigned: false,
      jobOriginLatitude: lat,
      jobOriginLongitude: lng
    };

    if (this.componentType === 'Multiple Assign') {
      delete data.isNearBy;
    }
    this.driverService
      .getNearbyDrivers(data)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data && data.drivers) {
          this.nearbyDrivers = data.drivers;
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  getDriverList() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.activeDrivers = data;
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }


  onSelectDriver(driver) {
    this.selectedDriver = driver;
    if (this.componentType !== 'Multiple Assign')
      this.driverLoc = {
        lat: driver.coords[1],
        lng: driver.coords[0]
      };
    this.getManifestTrips();
  }

  assignTrip() {
    this.isSubmitted = true;
    if (this.selectedDriver) {
      if (this.componentType === 'Multiple Assign') {
        this.requestType = 'Multiple Assign';
        const data = {
          selectedTrips: this.trip,
          status: 'offered',
          driver: this.selectedDriver,
          note: this.multiAssignTripNote
        };
        this.assignMultipleTrips(data);
      } else {
        this.requestType = 'Assign';
        this.trip.driver = this.selectedDriver._id;
        this.trip.status = 'offered';
        this.trip.jobStatus = 'offered';
        this.assignSingleTrip(this.trip);

      }
    }
  }

  assignSingleTrip(payload) {

    if (this.isAttendee && this.nearbyDrivers.length) {
      const attendeeDriver = this.nearbyDrivers.find(driver => driver._id === this.isAttendee);
      if (attendeeDriver)
        payload = { ...payload, attendee: { _id: attendeeDriver._id, contactNumber: attendeeDriver.contactNumber } }
    }

    this.assignTripService
      .assignTrip(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          if (this.requestType === 'Un Assign') {
            this.tripAssigned.emit('Trip Un Assigned');
          } else if (this.requestType === 'Assign') {
            this.tripAssigned.emit('Trip Assigned');
          }
        }
      });
  }

  assignMultipleTrips(payload) {
    this.assignTripService
      .assignMultipleTrip(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.tripAssigned.emit('All Trips Assigned');
        }
      })
  }

  unAssignTrip() {
    // this.trip.status = 'unassigned';
    // this.trip.jobStatus = 'pending';
    // this.assignTripService
    //   .assignTrip(this.trip)
    //   .pipe(takeUntil(componentDestroyed(this)))
    //   .subscribe(data => {
    //     if (data) {
    //       this.tripAssigned.emit('Trip Un Assigned');

    //     }
    //   });
    let payload;
    if (this.componentType === 'Multiple Assign') {
      // console.log("Check if archive", this.trip)
      payload = {
        selectedTrips: this.trip,
        status: 'unassigned'
      }
      this.updateMultipleTrips(payload);
    } else {
      this.trip.jobStatus = 'pending';
      this.trip.status = 'unassigned';
      this.updateSingleTrip(this.trip);
    }
  }

  archiveTrip() {
    let payload;
    if (this.componentType === 'Multiple Assign') {
      // console.log("Check if archive", this.trip)
      payload = {
        selectedTrips: this.trip,
        status: 'archived'
      }
      this.updateMultipleTrips(payload);
    } else {
      this.trip.status = 'archived';
      this.updateSingleTrip(this.trip);
    }
    // sweetAlert('Alert','Are you sure you want to archive the trip?','warning','Yes','No')
    // .then((result) => {
    //   if(result.value) {
    //     this.trip.status = "archived";
    //     this.assignTripService
    //       .assignTrip(this.trip)
    //       .subscribe(data => {
    //       if (data) {
    //         console.log('Trip Archived: ', data);
    //       }
    //   });
    //   }
    //   else if(result.dismiss){
    //     // close();
    //   }
    // });
  }

  updateJob(assign, assignKey) {
    console.log(assign, assignKey);
    let params = {
      assignId: assign._id
    }
    params[assignKey] = !assign[assignKey];
    this.socketService.emit(Events.UPDATE_ASSIGN_TRIP, params);
    this.tripAssigned.emit('Trip Assigned');
  }

  sendToMarketplace() {
    let payload;
    if (this.componentType === 'Multiple Assign') {
      payload = {
        selectedTrips: this.trip,
        status: 'marketplaced'
      }
      this.updateMultipleTrips(payload);

    } else {
      this.trip.jobStatus = 'marketplaced';
      this.trip.status = 'unassigned'
      this.updateSingleTrip(this.trip);

    }
  }

  setRequestType(requestType) {
    this.requestType = requestType;
  }

  getETA() {
    this.assignTripService
      .getETA(this.trip._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          console.log(data);
          this.trip.etaMessage = data.etaMessage;
        }
      });
  }

  getMiles(driverIndex, driver) {
    this.googleSelectedDriver = null;

    if (this.trip.jobOriginLatitude && this.trip.jobOriginLongitude && this.trip.jobDestinationLatitude && this.trip.jobDestinationLongitude) {
      const locationParams = {
        origin: this.trip.jobOriginLatitude + ' , ' + this.trip.jobOriginLongitude,
        destination: driver.coords[1] + ' , ' + driver.coords[0]
        // destination: vm.assign.jobDestinationLatitude + ' , ' + vm.assign.jobDestinationLongitude
      };
      locationParams['dbMiles'] = driver.miles || 0;
      locationParams['isLog'] = true;
      this.socketService.emit(Events.GET_DRIVER_MILES_FOR_ASSIGN_MODAL, locationParams);
      // Socket.emit(‘assignTripManualFormETA’, locationParams);
      this.googleSelectedDriver = driver;
    } else {
      sweetAlert('Error', 'Please Select Pickup and DropOff Address', 'warning', 'OK');
    }
  }

  listenSocket() {
    this.socketService.addListener(Events.UPDATE_ASSIGN_TRIP)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data.success) {
          // sweetAlert('Success', data.message, 'success', 'OK');
          if (data.assign) {
            this.trip.isReadyForPickup = data.assign.isReadyForPickup;
            this.trip.isVipTrip = data.assign.isVipTrip;
            this.trip.isConfirmedTrip = data.assign.isConfirmedTrip;
            this.trip.isVoiceMail = data.assign.isVoiceMail;
          }
        } else {
          sweetAlert('Error', data.message, 'warning', 'OK');
        }
      });

    this.socketService.addListener(Events.GET_DRIVER_MILES_FOR_ASSIGN_MODAL)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (!this.googleSelectedDriver) return;
        const driverIndex = this.nearbyDrivers.findIndex(driver => driver._id === this.googleSelectedDriver._id);
        if (data.success) {
          console.log(data)
          this.nearbyDrivers[driverIndex].googleETA = data.message;
        } else {
          this.nearbyDrivers[driverIndex].googleETA = null;
        }
      });
  }

  cancelTrip() {
    this.cancelTripModalObj = this.trip;
  }

  closeModal() {
    this.onCloseModal.emit('close');
  }

  closeConfirmation() {
    this.requestType = '';
  }

  modalClose() {
    $('#assignModal .close').click();
  }

  closeCancelModal() {
    this.cancelTripModalObj = null;
    this.tripAssigned.emit('Trip Updated');
    // this.onCloseModal.emit('close');
  }

  // <p class="text-sm text-main-light" *ngIf = "requestType === 'archive'" class="modal-title" id="myLargeModalLabel"> Are you sure you want to archive ?</p>
  //                 <p class="text-sm text-main-light" *ngIf = "requestType === 'market place'" class="modal-title" id="myLargeModalLabel">Are you sure you want to send this trip to marketplace?</p>
  //               <p class="text-sm text-main-light" *ngIf = "requestType === 'Un Assign'" class="modal-title" id="myLargeModalLabel">Are you sure you want to send this trip to unassigned?</p>
  _updateJob(trip) {
    let payload;
    if (this.componentType === 'Multiple Assign') {
      if (this.requestType === 'archive') {
        payload = {
          selectedTrips: trip,
          status: 'archived'
        }
      } else if (this.requestType === 'market place') {
        payload = {
          selectedTrips: trip,
          status: 'marketplaced'
        }
      } else if (this.requestType === 'Un Assign') {
        payload = {
          selectedTrips: trip,
          status: 'unassigned'
        }
      }
      this.updateMultipleTrips(payload);
    } else {
      if (this.requestType === 'archive') {
        this.trip.status = 'archived';
      } else if (this.requestType === 'market place') {
        this.trip.jobStatus = 'marketplaced';
      } else if (this.requestType === 'Un Assign') {
        this.trip.status = 'unassigned';
        this.trip.jobStatus = 'pending';
      }
      this.updateSingleTrip(trip);
    }
  }

  updateMultipleTrips(payload) {
    this.assignTripService
      .assignMultipleTrip(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.tripAssigned.emit('Trip Assigned');
          // sweetAlert('Success', 'Trip Updated', 'success', 'OK');
        }
      });
  }

  updateSingleTrip(payload) {
    this.assignTripService
      .updateTrip(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.tripAssigned.emit('Trip Assigned');
          // sweetAlert('Success', 'Trip Updated', 'success', 'OK');
        }
      });
  }

  returnTrip() {
    this.cancelTrip();
  }

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }


  getManifestTrips() {
    const payload = {
      scheduleTime: this.trip.scheduleTime,
      driverId: this.selectedDriver._id
    };
    this.manifestList = [];
    this.assignTripService
      .fetchManifestTrips(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(async data => {
        if (data && data.assigns) {
          this.manifestList = data.assigns;
          const findTrip = this.manifestList.find(item => item._id === this.trip._id)
          if (!findTrip) {
            this.manifestList.push({ ...this.trip, isPurposeTrip: true });
          } else {
            findTrip.isPurposeTrip = true;
          }
         await this.manifestList.sort(
            (a, b) => <any>new Date(a.scheduleTime) - <any>new Date(b.scheduleTime)
          );

          await this.checkPossibility();
        }
      });
  }

 async validateUberDrivers(){
   const res = await this.driverService
      .getUberDrivers().toPromise();
      return res;
  }

  async uberFareEstimation() {
    console.log(this.formatPhoneNumber('(804) 718-0909'))
    if (this.isUberDataLoading) return;
    this.isUberDataLoading = true;
    const uberDriver = await this.validateUberDrivers();
    if (!uberDriver) {
      this.isUberDataLoading = false;
      sweetAlert('Alert', `No uber driver found in system`, 'warning', 'OK')
      return
    }
    console.log("Check working", uberDriver)
    const payload = {
      "pickup": {
        "latitude": this.trip.jobOriginLatitude,
        "longitude": this.trip.jobOriginLongitude
      },
      "dropoff": {
        "latitude": this.trip.jobDestinationLatitude,
        "longitude": this.trip.jobDestinationLongitude
      }
    }

    this.assignTripService
      .uberFareEstimation(payload)
      .subscribe(data => {
        if (data) {
          this.uberVehicles = data.product_estimates
          this.isUberDataLoading = false;
        } else {
          this.isUberDataLoading = false;
        }
      }, err => {
        this.isUberDataLoading = false;
      });
  }

  onSelectUberVehicle(vehicleFare) {
    this.selectedUberVehicle = vehicleFare;
    console.log('Uber: ', vehicleFare);
    sweetAlert('Alert', `Are you sure you want to create uber ride?`, 'warning', 'Yes', 'No')
      .then((result) => {
        if (result.value) {
          this.createUberRequest();
        }
      });
  }

  async createUberRequest() {
    const uberDriver:any = await this.validateUberDrivers();
    if (!uberDriver) {
      sweetAlert('Alert', `No uber driver found in system`, 'warning', 'OK')
      return
    }

    const myUser = this.user && this.user.company ? this.user.company : this.user;
    const priorityClient = this.trip.priorityClient.displayName.split(' ');
    const payload = {
      "guest": {
        "first_name": priorityClient[0],
        "last_name": priorityClient.length > 1 ? priorityClient[1] : priorityClient[0],
        "phone_number":  this.formatPhoneNumber(this.trip.priorityClient.contactNumber),
        // "email": this.trip.priorityClient.email?this.trip.priorityClient.email:'',
      },
      "pickup": {
        "address": this.trip.jobOriginAddress,
        "latitude": this.trip.jobOriginLatitude,
        "longitude": this.trip.jobOriginLongitude
      },
      "dropoff": {
        "address": this.trip.jobDestinationAddress,
        "latitude": this.trip.jobDestinationLatitude,
        "longitude": this.trip.jobDestinationLongitude
      },

      "product_id":  this.selectedUberVehicle.product.product_id,
      "fare_id":  this.selectedUberVehicle.estimate_info.fare_id,
      "expense_memo": uberDriver._id,
      "sender_display_name":myUser.displayName,
      "note_for_driver": `If passenger is not visible then contact on this number '${myUser.contactNumber}'.`,
      "contacts_to_notify" : [
        {
            "phone_number": this.formatPhoneNumber(myUser.contactNumber),
            "contact_event": ["TRIP_BEGAN"]
        },
        {
            "phone_number": this.formatPhoneNumber(myUser.contactNumber),
            "contact_event": ["DRIVER_PICKUP_COMPLETED"]
        }
      ]

    }

    this.assignTripService
      .uberRideRequest(payload)
      .subscribe(data => {
        if (data) {
          this.trip.uber = {
            "requestId": data.request_id,
            "productId": data.product_id,
            "message": '',
            "status": 'processing',
            "createdAt": moment().tz(this.timeZone).toISOString()
          }
          this.trip.driver = uberDriver._id;
          this.trip.status = 'offered';
          this.trip.jobStatus = 'offered';
          this.updateSingleTrip(this.trip);
          // sweetAlert('Success', 'Uber searching for a driver', 'success', 'OK')
        }
      }, err => {
        sweetAlert('Error', "Create Ride error",'warning', 'OK');
      });
  }

  async checkPossibility() {
    let lastTrip;
    if (this.selectedDriver && this.selectedDriver.coords && this.selectedDriver.coords.length ) {
      lastTrip = this.selectedDriver;
    }

    for (let myTrip of this.manifestList) {
      if (!lastTrip) {
          lastTrip = myTrip;
        continue
      }
      await this.setNextTripPickupTime(lastTrip, myTrip);
      lastTrip = myTrip;
    }
  }
  async setNextTripPickupTime(lastTrip, currentTrip) {
    let puDistanceAndTime;
    if (lastTrip.coords && lastTrip.coords.length) {
      puDistanceAndTime = await getTimeAndDistance(
        lastTrip.coords[1], lastTrip.coords[0],
          currentTrip.jobOriginLatitude, currentTrip.jobOriginLongitude, 50
        );
    } else {
      puDistanceAndTime = await getTimeAndDistance(
        lastTrip.jobDestinationLatitude, lastTrip.jobDestinationLongitude,
          currentTrip.jobOriginLatitude, currentTrip.jobOriginLongitude, 50
        );
    }




    let minutesToAdd = 0;
    if (puDistanceAndTime) {
      if (currentTrip.tripRequirement === 'WAV') {
        minutesToAdd += 5;
      } else if (currentTrip.tripRequirement === 'Stretcher') {
        minutesToAdd += 15;
      } else {
        minutesToAdd += 2;
      }

      let approxPUCurrentTrip;
      if (lastTrip.coords && lastTrip.coords.length) {
        approxPUCurrentTrip =  moment().tz(this.user.timeZone).add(puDistanceAndTime.timeInMinutes * minutesToAdd, 'minutes');

      } else {
        const lastAptTimePredic = lastTrip.approxDO ? lastTrip.approxDO.time : lastTrip.appointmentTime;
        approxPUCurrentTrip = moment(this.isLivePrediction ? lastAptTimePredic : lastTrip.appointmentTime).tz(this.user.timeZone).add(puDistanceAndTime.timeInMinutes * minutesToAdd, 'minutes');
      }
      // to check late or on time
      let approxPUCurrentTripDiff = moment(currentTrip.scheduleTime).tz(this.user.timeZone).diff(approxPUCurrentTrip, 'minutes', true);


      currentTrip.approxPU = {
        time: approxPUCurrentTrip.toISOString(),
        difference: approxPUCurrentTripDiff,
        pointsDistance:puDistanceAndTime
      };

      const doDistanceAndTime = await getTimeAndDistance(
        currentTrip.jobOriginLatitude, currentTrip.jobOriginLongitude,
        currentTrip.jobDestinationLatitude, currentTrip.jobDestinationLongitude, 50
      );

      if (doDistanceAndTime) {

        let approxDOCurrentTrip = moment(currentTrip.approxPU.time).tz(this.user.timeZone).add(doDistanceAndTime.timeInMinutes*minutesToAdd, 'minutes');
        // to check late or on time
        let approxDOCurrentTripDiff = moment(currentTrip.appointmentTime).tz(this.user.timeZone).diff(approxDOCurrentTrip, 'minutes', true);

        currentTrip.approxDO = {
          time: approxDOCurrentTrip.toISOString(),
          difference: approxDOCurrentTripDiff,
          pointsDistance:doDistanceAndTime
        };

      }
    }
  }


  onSelectedManifestTrip(item) {
    if (item.isPurposeTrip) return;
    item.isSelected = !item.isSelected;
  }

  async changePrediction() {
    this.isLivePrediction = !this.isLivePrediction;
    await this.checkPossibility();
  }

  ngOnDestroy(): void {
  }

  formatPhoneNumber = (input) => {
    // Remove all non-numeric characters
    let cleaned = input.replace(/\D/g, '');

    // Ensure it starts with the country code (e.g., "1" for US)
    if (cleaned.length === 10) {
        // Assume it's a US number, prepend the country code (+1)
        cleaned = '1' + cleaned;
    }

    // If the number is longer than expected, truncate it to 11 digits (for US)
    cleaned = cleaned.slice(0, 11);

    // Format the number in +1XXXXXXXXXX format
    return `+${cleaned}`;
}

}
