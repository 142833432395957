import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../service/auth-service/auth.service';
import { imageProcessing } from '../../../utils/image-processing-utils';
import { sweetAlert } from '../../../utils/swal';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { DriversService } from '../drivers-service/drivers-service';
import { Console } from 'console';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, takeUntil } from 'rxjs/operators';
import { setFormData, errorHandl } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { dataURItoBlob } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-create-driver',
  templateUrl: './create-driver.component.html',
  styleUrls: ['./create-driver.component.css']
})
export class CreateDriverComponent implements OnInit, OnDestroy {
  createDrivierForm: FormGroup;
  submitted = false;
  selectedProfileImageFile = null;
  selectedLicenseImageFile = null;

  profileImageUrl = null;
  LicenseImageUrl = null;
  driverSignatureImage = null;
  passwordToggleFlag = true;
  userId = null;
  driverUserId = '';
  btnName = 'Create';
  signatureModalObj;

  constructor(
    private driverService: DriversService,
    private fb: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
  ) {
    // this.getDriverState = this.store.select(selectDriversState);
  }


  ngOnInit() {
    this.activatedRoute.params
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((params) => {
      this.driverUserId = params["id"];
    });

    this.createDrivierForm = this.fb.group({
      legalName: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      address: ['', Validators.required],
      accountStatus: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      driverLicenseNumber: ['', Validators.required],
      profileRole: ['', Validators.required],
      licenseRegistrationExpiry: ['', Validators.required],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      images: [''],
      driverId: [''],
      licenseRegistrationExpiryDate: [''],
      employeeId: [''],
      startingPointAddress: this.fb.group({
        address: [''],
        latitude: [''],
        longitude: ['']
      }),

      isUber: [false]
    });

    this.fillData();
  }

  fillData() {
    if (this.driverUserId) {

      // console.log('Driver id here ==>', this.driverUserId)

      this.driverService
        .getDriverProfile(this.driverUserId)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            this.createDrivierForm.patchValue({
              ...data,
              licenseRegistrationExpiryDate: data.licenseRegistrationExpiry,
              password: '******'
            });
            this.profileImageUrl = data.profileImageURL;
            this.LicenseImageUrl = data.driverLicenseImage;
            this.driverSignatureImage = data.driverSignatureImage;
          }
        });
    }
  }

  get form() {
    return this.createDrivierForm.controls;
  }

  processLicenseFile(ImageInput: any) {
    imageProcessing(ImageInput)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((result) => {
      this.selectedLicenseImageFile = result.file;
      this.LicenseImageUrl = result.src;
    });
  }

  processProfileFile(ImageInput: any) {
    imageProcessing(ImageInput)
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((result) => {
      this.selectedProfileImageFile = result.file;
      this.profileImageUrl = result.src;


    });

  }

  async autoSelectImage(ImageInput) {
    const src = ImageInput.target.src;
    const splitArry = src.split("/");
    const fileName = splitArry[splitArry.length - 1].split(".")[0];

    fetch(src)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], fileName, { type: "image/png" })
        console.log(file);
        this.selectedProfileImageFile = file;
        this.profileImageUrl = src;
      })


  }

  getAddress(place) {
    this.createDrivierForm.patchValue({
      address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng()
    });
  }

  togglePasswordType() {
    this.passwordToggleFlag = !this.passwordToggleFlag;
  }

  getAccountStatus() {
    return this.authService.accountStatus;
  }

  getProfileRole() {
    return this.authService.driverComponentProfileRoles;
  }

  onCreateDriver() {
    this.submitted = true;

    if (this.createDrivierForm.invalid) {
      return;
    }
    // tslint:disable-next-line: max-line-length
    if ((this.selectedProfileImageFile == null && this.profileImageUrl == null)
      || (this.selectedLicenseImageFile == null && this.LicenseImageUrl == null)) {
      return;
    }
    const image = {
      driverLicenseImage: this.selectedLicenseImageFile,
      profileImageURL: this.selectedProfileImageFile,
    };
    this.createDrivierForm.patchValue({
      images: image,
      driverId: this.driverUserId,
    });
    delete this.createDrivierForm.value.licenseRegistrationExpiryDate;
    console.log(this.createDrivierForm, "onCreateDriver After");
    if (!this.createDrivierForm.value.startingPointAddress.address) {
      this.createDrivierForm.patchValue({
        startingPointAddress: {
          latitude: '',
          longitude: ''
        }
      });
    }
    if (!this.driverUserId) {
      this.saveDriver(this.createDrivierForm.value);
    } else {
      delete this.createDrivierForm.value.password;
      this.saveDriver(this.createDrivierForm.value);
    }
  }

  removeLicenseImage() {
    this.LicenseImageUrl = "";
  }

  removeSignatureImage() {
    this.driverSignatureImage = "";
  }

  saveDriver(payload) {
    this.driverService
      .saveDriver(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.submitted = false;
          if (payload.driverUserId) {
            this.router.navigateByUrl('/drivers/' + data._id + '/profile');
          } else {
            this.router.navigateByUrl('credentialing/drivers');
          }
        }
      }, err => {
        console.log(err, 'check error')
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  fillDummyData() {
    this.createDrivierForm.patchValue({
      legalName: 'Jhon Doe',
      firstName: 'Jhon',
      lastName: 'Doe',
      contactNumber: '123456789',
      accountStatus: 'active',
      email: 'JhonDoe@Test.com',
      password: '123456789',
      driverLicenseNumber: '12345',
      profileRole: 'driver'
    });
  }

  clearData() {
    this.createDrivierForm.reset();
  }

  onDateChange(key,event) {
    // to convert License Registration Expiry date into ISO string from moment object
    if (event) {
      this.createDrivierForm.patchValue({
        [key]: moment(event).toISOString(),
      });
    }

  }

  showSignatureModal(id) {
    this.signatureModalObj = id;
  }

  onSaveSignature(data){
    if (data) {
      const imageBlob = dataURItoBlob(data);
      const imageFile = new File([imageBlob], "signature.png", { type: 'image/png' });
      const payload = {
        _id: this.driverUserId,
        driverSignatureImage: imageFile
      };
      this.driverService
        .uploadSignature(payload)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(data => {
          if (data) {
            this.driverSignatureImage = data.driver.driverSignatureImage
            this.signatureModalObj = null;
          }
        });
    }
  }

  setStartingPointAddress(place) {
    this.createDrivierForm.patchValue({
      startingPointAddress: {
        address: place.formatted_address,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng()
      }
    });
  }


  toggleUberDriver() {
    this.createDrivierForm.patchValue({
      isUber: !this.createDrivierForm.value.isUber
    });
  }

  ngOnDestroy(): void {
  }
}
