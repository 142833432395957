import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { Events } from 'src/app/utils/event-utils';
import { sweetAlert } from 'src/app/utils/swal';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { NewTripComponent } from '../new-trip/new-trip.component';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-edit-trip-modal',
  templateUrl: './edit-trip-modal.component.html',
  styleUrls: ['./edit-trip-modal.component.css']
})
export class EditTripModalComponent implements OnInit, OnDestroy {
  @Input() tripId: any;
  @Input() trip?: any;
  @Input() isManifest: boolean = false;
  @Output() tripEditResponse = new EventEmitter<string>();
  @Output() onCloseModal = new EventEmitter<string>();
  drivers = [];
  searchDriver = '';
  selectedDriver;
  selectedDriverId;
  driverIndex = -1;
  isSubmitted = false;
  origin = { lat: 0.00000, lng: 0.00000 };
  destination = { lat: 0.00000, lng: 0.00000 };
  driverLoc = { lat: 0.00000, lng: 0.00000 };
  isSubmitForm = false;
  @ViewChild('newTripForm', { static: false }) newTripForm: NewTripComponent;
  isDriverRequired = false;
  cancelTripModalObj;

  constructor(
    private driverService: DriversService,
    private assignTripService: AssigTripsService,
    private socketService: SocketService
  ) { }

  ngOnInit() {
    console.log(this.trip);
    if (this.trip) {
      this.origin = {
        lat: this.trip.jobOriginLatitude,
        lng: this.trip.jobOriginLongitude
      };
      this.destination = {
        lat: this.trip.jobDestinationLatitude,
        lng: this.trip.jobDestinationLongitude
      };
      this.getNearbyDriver(this.trip.jobOriginLatitude, this.trip.jobOriginLongitude);
      this.setDriver();
    }
    this.listenSocket();
  }

  setDriver() {
    if (this.trip.driver) {
      if (typeof this.trip.driver === 'string') {
        this.selectedDriverId = this.trip.driver;
      } else {
        this.selectedDriverId = this.trip.driver._id;
      }
      // this.selectedDriver = this.trip.driver;
      // this.selectedDriverId = this.trip.driver._id;
    }
  }

  getNearbyDriver(lat, lng) {
    let data = {
      isNearBy: true,
      companyType: this.trip.companyType,
      // isReassigned: this.trip.driver ? true : false,
      isReassigned: false,
      jobOriginLatitude: lat,
      jobOriginLongitude: lng
    };

    this.driverService
      .getNearbyDrivers(data)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data && data.drivers) {
          this.drivers = data.drivers;
          // if (this.trip.driver) {
          //   const driver = this.drivers.find(driver => driver._id === this.trip.driver._id);
          //   if (driver) {
          //     this.selectedDriver = driver;
          //   }
          // }
        }
      });
  }

  onSelectDriver(driver) {
    this.selectedDriver = driver;
    this.selectedDriverId = driver._id;
    this.driverLoc = {
      lat: driver.coords[1],
      lng: driver.coords[0]
    };
  }

  getMiles(driverIndex, driver) {
    this.driverIndex = -1;

    if (this.trip.jobOriginLatitude && this.trip.jobOriginLongitude && this.trip.jobDestinationLatitude && this.trip.jobDestinationLongitude) {
      const locationParams = {
        origin: this.trip.jobOriginLatitude + ' , ' + this.trip.jobOriginLongitude,
        destination: driver.coords[1] + ' , ' + driver.coords[0]
        // destination: vm.assign.jobDestinationLatitude + ' , ' + vm.assign.jobDestinationLongitude
      };
      locationParams['dbMiles'] = driver.miles || 0;
      locationParams['isLog'] = true;
      this.socketService.emit(Events.GET_DRIVER_MILES_FOR_ASSIGN_MODAL, locationParams);
      // Socket.emit(‘assignTripManualFormETA’, locationParams);
      this.driverIndex = driverIndex;
    } else {
      sweetAlert('Alert', 'Please Select Pickup and DropOff Address', 'warning', 'OK');
    }
  }

  listenSocket() {
    this.socketService.addListener(Events.GET_DRIVER_MILES_FOR_ASSIGN_MODAL)
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data.success) {
          this.drivers[this.driverIndex].googleETA = data.message;
        } else {
          this.drivers[this.driverIndex].googleETA = null;
        }
      });

    this.socketService.addListener(Events.UPDATE_ASSIGN_TRIP)
    .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data.success) {
          // sweetAlert('Success', data.message, 'success', 'OK');
          if (data.assign) {
            this.trip.isReadyForPickup = data.assign.isReadyForPickup;
            this.trip.isVipTrip = data.assign.isVipTrip;
            this.trip.isConfirmedTrip = data.assign.isConfirmedTrip;
            this.trip.isVoiceMail = data.assign.isVoiceMail;
          }
        } else {
          sweetAlert('Error', data.message, 'warning', 'OK');
        }
      });
  }

  assignTrip() {
    this.isSubmitted = true;
    if (this.selectedDriver) {
      this.trip.driver = this.selectedDriver._id;
      this.trip.status = 'offered';
      this.trip.jobStatus = 'offered';
      this.assignSingleTrip(this.trip);
    }
  }

  assignSingleTrip(payload) {
    this.assignTripService
      .assignTrip(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.tripEditResponse.emit(data);
        }
      });
  }

  updateJob(assign, assignKey) {
    let params = {
      assignId: assign._id
    }
    params[assignKey] = !assign[assignKey];
    this.socketService.emit(Events.UPDATE_ASSIGN_TRIP, params);
    // this.tripAssigned.emit('Trip Assigned');
  }

  getETA() {
    this.assignTripService
      .getETA(this.trip._id)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.trip.etaMessage = data.etaMessage;
        }
      });
  }

  cancelTrip() {
    this.cancelTripModalObj = this.trip;
  }

  closeCancelModal(event) {
    this.cancelTripModalObj = null;
    this.tripEditResponse.emit(event);
  }

  unAssignTrip() {
    this.trip.status = 'unassigned';
    this.trip.jobStatus = 'pending';
    this.assignTripService
      .assignTrip(this.trip)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.tripEditResponse.emit(data);
        }
      });
  }

  archiveTrip() {
    this.trip.status = 'archived';
    this.updateSingleTrip(this.trip);
  }

  updateSingleTrip(payload) {
    this.assignTripService
      .updateTrip(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data) {
          this.tripEditResponse.emit(data);
        }
      });
  }

  sendToMarketplace() {
    this.trip.jobStatus = 'marketplaced';
    this.trip.status = 'unassigned'
    this.updateSingleTrip(this.trip);
  }

  saveTrip() {
    if (this.trip.status === 'standing') {
      this.standingOrderAlert();
    }
    else {
      this.tripUpdate();
    }
  }

  standingOrderAlert() {
    sweetAlert('Alert', 'Upon updating, the system will overwrite existing standing order trips and update driver(s) if assigned/unassigned from here again for a particular day.', 'warning', 'Yes', 'No')
      .then((result) => {
        if (result.value) {
          this.tripUpdate();
        }
      })
  }

  tripUpdate(){
    if (this.trip.status === 'offered' && !this.selectedDriverId) {
      this.isDriverRequired = true;
      this.newTripForm.isSaveDisabled = false;
    }
    this.newTripForm.onNewTripSubmit();
  }

  closeModal() {
    this.onCloseModal.emit();
  }

  onTripEdit(event: any) {
    this.tripEditResponse.emit(event);
  }

  ngOnDestroy(): void {
  }
}
